import React from "react";
import Layout from "../../components/Layout";

export default function objectives() {
    return (
        <Layout mainClass="objectives" title="Objectives">
            <h1 className="section-heading mx-1 mx-md-5 px-0 px-md-3">Program Educational Objectives (PEOs) [BTech]</h1>
            <div className="mx-2 mx-md-5 px-0 px-md-5">
                <ol>
                    <li>To undertake industry careers involving innovation and
                        problem solving using
                        software and other information technologies.</li>
                    <li>To undertake advanced studies for research careers in
                        different areas of
                        computer sciences and allied areas.</li>
                    <li>To undertake entrepreneurship as a career by creating
                        products and services
                        using technology to provide value to some sections of
                        the society.</li>
                </ol>
            </div>

            <hr />

            <h1 className="section-heading mx-1 mx-md-5 px-0 px-md-3">Program
                Outcomes (POs) [BTech]</h1>
            <div className="mx-2 mx-md-5 px-0 px-md-5">
                <ol>
                    <li><b>Engineering knowledge:</b> Apply the knowledge of mathematics, science, engineering fundamentals and an engineering specialization to the solution of complex engineering problems.
                    </li>
                     <li><b>Problem analysis:</b> Identify, formulate, review research literature, and analyze complex engineering problems reaching substantiated conclusions using first principles of mathematics, natural sciences, and engineering sciences.
                    </li>
                    <li><b>Design/development of solutions:</b> Design solutions for complex engineering problems and design system components or processes that meet the specified needs with appropriate consideration for the public health and safety, and the cultural, societal, and environmental considerations.
                    </li>
                    <li><b>Conduct investigations of complex problems:</b> Use research-based knowledge and research methods including design of experiments, analysis and interpretation of data, and synthesis of the information to provide valid conclusions.
                    </li>
                    <li><b>Modern tool usage:</b> Create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex engineering activities with an understanding of the limitations.
                    </li>
                    <li><b>The engineer and society:</b> Apply reasoning informed by the contextual knowledge to assess societal, health, safety, legal and cultural issues and the consequent responsibilities relevant to the professional engineering practice.
                    </li>
                    <li><b>Environment and sustainability:</b> Understand the impact of the professional engineering solutions in societal and environmental contexts, and demonstrate the knowledge of, and need for sustainable development.
                    </li>
                    <li><b>Ethics:</b> Apply ethical principles and commit to professional ethics and responsibilities and norms of the engineering practice.
                    </li>
                    <li><b>Individual and team work:</b> Function effectively as an individual, and as a member or leader in diverse teams, and in multidisciplinary settings.
                    </li>
                    <li><b>Communication:</b> Communicate effectively on complex engineering activities with the engineering community and with society at large, such as, being able to comprehend and write effective reports and design documentation, make effective presentations, and give and receive clear instructions.
                    </li>
                    <li><b>Project management and finance:</b> Demonstrate knowledge and understanding of the engineering and management principles and apply these to one’s own work, as a member and leader in a team, to manage projects and in multidisciplinary environments.
                    </li>
                    <li><b>Life-long learning:</b> Recognize the need for, and have the preparation and ability to engage in independent and life-long learning in the broadest context of technological change.
                    </li>
                </ol>
            </div>
    
           <hr />
    <h1 className="section-heading mx-1 mx-md-5 px-0 px-md-3">Program
                Specific Outcomes (PSOs) [BTech (CSE)]</h1>
            <div className="mx-2 mx-md-5 px-0 px-md-5">
                The outcomes specific to the BTech (CSE) program are given here. At
                the end of the program, a student should have:
                <ol>
                    <li>Understanding of theoretical foundations and limits of
                        computing.
                    </li>
                    <li>Understanding of computing at different levels of
                        abstraction including circuits and
                        computer architecture, operating systems, algorithms,
                        and applications.
                    </li>
                    <li>Ability to adapt established models, techniques,
                        algorithms, data structures, etc. for
                        efficiently solving new problems.
                    </li>
                    <li>
                        Ability to design, implement, and evaluate computer
                        based system or application to meet
                        the desired needs using modern tools and methodologies.
                    </li>
                    <li>
                        Understanding and ability to use advanced techniques and
                        tools in different areas of
                        computing.
                    </li>
                </ol>
            </div>

            <hr />
                            
                            <h1 className="section-heading mx-1 mx-md-5 px-0 px-md-3">Program
                Specific Outcomes (PSOs) [BTech (CSAI)]</h1>
            <div className="mx-2 mx-md-5 px-0 px-md-5">
                The outcomes specific to the BTech (CSAI) program are given here. At the end of this program, a student should have:
                <ol>
                    <li>Understanding of foundational topics in Computer Science, Artificial Intelligence, and Machine Learning.
                    </li>
                    <li>Understanding of theoretical foundations and limits of Artificial Intelligence and Machine Learning.
                    </li>
                    <li>Ability to design and implement algorithms and data structures for efficiently solving new problems.
                    </li>
                    <li>
                        Ability to model and analyze a variety of problems using appropriate mathematical/computational and AI concepts.
                    </li>
                    <li>
                        Ability of apply and develop AI algorithms to transform large amount of data into intelligent decisions and/or behavior.
                    </li>
                     <li>
                        An understanding of the impact of AI based solutions in an economic, societal, and environment context.
                    </li>
                </ol>
            </div>

            <hr />
            <h1 className="section-heading mx-1 mx-md-5 px-0 px-md-3">Program Educational Objectives (PEOs) [MTech (CSE)]</h1>
            <div className="mx-2 mx-md-5 px-0 px-md-5">
                <ol>
                    <li>To undertake industry careers involving innovation and problem solving using software and other information technologies.</li>
                    <li>To undertake research careers in Computer Sciences and allied areas.</li>
                    <li>To contribute to society by becoming a model professional who can communicate effectively and observes ethical behavior.</li>
                </ol>
            </div>

            <hr />
            <h1 className="section-heading mx-1 mx-md-5 px-0 px-md-3">Program
                Outcomes (POs) [MTech (CSE)]</h1>
            <div className="mx-2 mx-md-5 px-0 px-md-5">
                The main outcomes of the MTech (CSE) program are given here. At
                the end of the program, a student should have:
                <br />
                <ol>
                    <li>
                        An understanding of the theoretical foundations and the
                        limits of computing.
                    </li>
                    <li>
                        An ability to adapt existing models, techniques,
                        algorithms, data structures, etc. for efficiently
                        solving problems.
                    </li>
                    <li>
                        An ability to design, develop and evaluate new computer
                        based systems for novel applications which meet the
                        desired needs of industry and society.
                    </li>
                    <li>
                        Understanding and ability to use advanced computing
                        techniques and tools.
                    </li>
                    <li>
                        An ability to undertake original research at the cutting
                        edge of computer science & its related areas.
                    </li>
                    <li>
                        An ability to function effectively individually or as a
                        part of a team to accomplish a state goal.
                    </li>
                    <li>
                        An understanding of professional and ethical
                        responsibility.
                    </li>
                    <li>
                        An ability to communicate effectively with a wide range
                        of audience.
                    </li>
                    <li>
                        An ability to learn independently and engage in
                        lifelong learning.
                    </li>
                    <li>
                        An understanding of the impact of IT related solutions
                        in an economic, societal, and environment context.
                    </li>
                </ol>
            </div>
        </Layout>
    )
}
